.video-responsive {
  /* overflow: hidden; */
  /* padding-bottom: 56.25%; */
  /* position: relative; */
  /* height: 500; */
}

.video-responsive iframe {
  left: 5;
  /* top: 0; */
  height: 85%;
  width: 97.5%;
  position: absolute;
}

